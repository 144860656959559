import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | Discover PixelPioneers Marketing Agency
			</title>
			<meta name={"description"} content={"Where Every Brand Has a Story to Tell"} />
			<meta property={"og:title"} content={"About Us | Discover PixelPioneers Marketing Agency"} />
			<meta property={"og:description"} content={"Where Every Brand Has a Story to Tell"} />
			<meta property={"og:image"} content={"https://zopicosold.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zopicosold.com/img/5225530.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zopicosold.com/img/5225530.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zopicosold.com/img/5225530.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zopicosold.com/img/5225530.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zopicosold.com/img/5225530.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zopicosold.com/img/5225530.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					About Us
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--darkL2"
					width="90%"
					lg-width="100%"
					sm-text-align="left"
				>
					Embark on the story of PixelPioneers, a tale of passion, innovation, and the relentless pursuit of marketing excellence. Born from the desire to create impactful digital experiences, we've grown from a humble team of digital enthusiasts to a full-fledged marketing agency. Our journey is fueled by the belief that every brand, no matter its size, has a unique story waiting to be told. Through ups and downs, we've remained committed to our mission – to bring those stories to life and help them resonate with audiences worldwide.
				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://zopicosold.com/img/6.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-light"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Our Expertise
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					At the heart of PixelPioneers are our people – a diverse team of creative wizards, marketing strategists, and technological innovators. Each member brings their unique flavor to the table, creating a melting pot of ideas that drive our agency forward. Our expertise spans across various domains, from SEO and content marketing to social media and brand development, ensuring a 360-degree approach to digital marketing. We pride ourselves on staying ahead of the curve, continuously evolving our skills to meet the ever-changing digital landscape.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Your Story, Amplified
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Imagine a place where your brand isn't just seen but heard, understood, and cherished. At PixelPioneers, we don't just market your products – we elevate your story, crafting campaigns that speak directly to your audience's hearts. Join us, and together, let's turn your brand into a beacon of inspiration, a testament to the power of authentic storytelling.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://zopicosold.com/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://zopicosold.com/img/8.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					What Sets Us Apart
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Innovative Approach: We dare to think differently, exploring creative avenues that others might overlook.
					<br/><br/>
Bespoke Solutions: Understanding that no two brands are the same, we tailor our strategies to fit your unique needs and objectives.
					<br/><br/>
Commitment to Growth: Your growth is our priority. We're not just about quick wins but sustainable success that propels your brand forward.
					<br/><br/>
Transparent Collaboration: We believe in building relationships based on trust, transparency, and mutual respect, keeping you in the loop every step of the way.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});